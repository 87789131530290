import React, { useEffect } from 'react';
import { BrowserRouter as Router, Route, Switch} from 'react-router-dom';
import {
  ActionTracker,
  TrackerProvider,
} from "@dpdgroupuk/react-event-tracker";
import './App.scss';
import Index from './pages/Index';

function App() {

  const tracker =  new ActionTracker({
    projectRef: "DriverPortal",
    // if debug is true then ConsoleLogger is used
    debug: false,
    // set the url to the app engine where you installed @dpdgroupuk/express-action-tracker
    url: process.env.REACT_APP_ANALYTICS_URL,
  });

  useEffect(() => {
    tracker.setContext({
      url: window.location.href,
    });

    tracker.logEvent();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <TrackerProvider tracker={tracker}>
      <Router>
        <Switch>
          <Route path="/">
            <Index pageId="bNka0SGFYFxgy5NH" />
          </Route>
        </Switch>
      </Router>
    </TrackerProvider>
  );
}

export default App;
