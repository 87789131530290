import React from 'react';
import ScrollAnimation from 'react-animate-on-scroll';
import {ANIMATION_DELAY} from 'constants/data';

export const BottomContent = (props) => {

  return (
    <section className="bottom-content">
      <div className="container">
        <ScrollAnimation
          animateIn="fadeInLeft"
          delay={ANIMATION_DELAY.default}
          initiallyVisible={false}
          animateOnce={true}
        >
          <div className="content-container">
            <h2 className='text-center'>A job that works around you</h2>
            <p>As you'd expect, working for one of the largest express delivery companies in the UK, you'll have lots of opportunities to progress your career. We love promoting and are always looking for those members of Team DPD that have that extra something. Join us now and find out what DPD can do for you.</p>
          </div>
        </ScrollAnimation>
      </div>
    </section>
  )
}

export default BottomContent;
