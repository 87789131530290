import React from 'react';
import { Link } from 'react-router-dom';
import logo from 'assets/images/logo.svg';

export const Header = () => {

  return (
    <header>
      <div className="container">

      {/* logo */}
      <Link className="logo" to="/">
        <img src={logo} alt="Warehouse Operative" width="89" />
      </Link>

      </div>
    </header>
  );
}

export default Header;
