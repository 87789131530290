import React, {useCallback} from 'react';
import { useTracker } from "@dpdgroupuk/react-event-tracker";
import ScrollAnimation from 'react-animate-on-scroll';
import {ANIMATION_DELAY} from 'constants/data';
import person1 from 'assets/images/person1.png';
import person2 from 'assets/images/person2.png';

export const Cards = () => {
  const tracker = useTracker();

  const handleClick = useCallback(
    e => {
      tracker.logEvent("53ehgSGGAqACBykl");
    },
    [tracker]
  );

  return (
    <section className="cards">

      {/*  desktop left person image*/}
      <ScrollAnimation
        animateIn="fadeInLeft"
        initiallyVisible={false}
        animateOnce={true}
        delay={ANIMATION_DELAY.slow}
        className="img-wrapper left d-none d-lg-block"
      >
        <div className='person1-bg'></div>
      </ScrollAnimation>

      {/*  desktop right person image*/}
      <ScrollAnimation
        animateIn="fadeInRight"
        initiallyVisible={false}
        animateOnce={true}
        delay={ANIMATION_DELAY.slow}
        className="img-wrapper right d-none d-lg-block"
      >
        <div className='person2-bg'></div>
      </ScrollAnimation>

      <div className="container outer-container">
        <ScrollAnimation
          animateIn="fadeIn"
          initiallyVisible={false}
          animateOnce={true}
          delay={ANIMATION_DELAY.slowest}
        >

          {/* images only for mobile */}
          <div className='images d-lg-none'>
            <img src={person1} alt="DPD employee" />
            <img src={person2} alt="DPD employee" />
          </div>

          <div className="content">
            <div className="card">

              <div className="primary-content">
                <h3>What does a Warehouse Operative do?</h3>
                <p>As a warehouse operative you will be required to unload, scan, sort and load parcels up to 23kg, ensuring that their journey through our network is safe and swift.</p>
                <p>It is a physical role with flexible working hours: full time, part-time, day or night shifts.</p>
                <p className='m-0'>Full training, uniform and PPE is provided.</p>
              </div>

              <div className="secondary-content">
                <h6 class="text-primary">You can expect:</h6>

                <ul>
                  <li>Salary: Up to £13.21 per hour</li>
                  <li>Flexible Working: Full time, part-time, day or night</li>
                  <li>Positions available across the UK</li>
                  <li>Plus many other benefits - Find out more <a href="#benefits">here</a></li>
                </ul>

                <a href="https://jobs.smartrecruiters.com/?company=DPDGroupUK1&keyword=Warehouse" target="_blank" rel="noreferrer" className='btn-anchor'>
                  <button className="btn btn-primary" onClick={() => handleClick()}>Find your new Job </button>
                </a>
              </div>
            </div>

          </div>
        </ScrollAnimation>

      </div>
    </section>
  )
}

export default Cards;
