import React, {useCallback} from 'react';
import { useTracker } from "@dpdgroupuk/react-event-tracker";
import ScrollAnimation from 'react-animate-on-scroll';
import {ANIMATION_DELAY} from 'constants/data';
import testimonial1 from 'assets/images/testimonial1.png';


export const Testimonial = () => {
  const tracker = useTracker();

  const handleClick = useCallback(
    e => {
      tracker.logEvent("zviRgSGGAqACBymu");
    },
    [tracker]
  );

  return (
    <section className="testimonial">
      <div className="container">

        <h2 className='text-center'>Hear from one of our Warehouse Operatives</h2>

        <div className='row justify-content-md-center'>

            <div className='col-md-8 col-lg-7'>
              <ScrollAnimation
                animateIn="fadeInLeft"
                delay={ANIMATION_DELAY.default}
                initiallyVisible={false}
                animateOnce={true}
              >
                <div className='padding-container'>
                  <h4 className='text-center'>Meet Mihaela, Warehouse Operative</h4>
                  <p className='text-center'>“The flexible working hours were important to me so I can still spend time with my family.”</p>
                  <img src={testimonial1} alt="Meet Mihaela, Warehouse Operative" width="100%" />
                </div>
              </ScrollAnimation>
            </div>

        </div>

        <ScrollAnimation
          animateIn="fadeIn"
          delay={ANIMATION_DELAY.default}
          initiallyVisible={false}
          animateOnce={true}
        >
          <div className='btn-row'>
            <a href='https://jobs.smartrecruiters.com/?company=DPDGroupUK1&keyword=Warehouse' target="_blank" rel="noreferrer">
              <button className='btn btn-primary' onClick={() => handleClick()}>Find your new Job</button>
            </a>
          </div>
        </ScrollAnimation>

      </div>
    </section>
  )
}

export default Testimonial;
