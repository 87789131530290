import React, {useCallback} from 'react';
import { useTracker } from "@dpdgroupuk/react-event-tracker";
import ScrollAnimation from 'react-animate-on-scroll';
import {ANIMATION_DELAY} from 'constants/data';

export const HexagonContent = (props) => {
  const {header, list, image, imageAlignment, anchorId} = props;
  const tracker = useTracker();

  const handleClick = useCallback(
    (imageAlignment) => {
      tracker.logEvent(imageAlignment === "right" ? "NiHhgSGGAqACByly" : "NiHhgSGGAqACByly");
    },
    [tracker]
  );

  return (
    <section className='hexagon-content' id={anchorId}>
      <div className="container">

        <div className={imageAlignment === "left" ? "row row-container left" : "row row-container right"} key={header}>

          {/* left image */}
          {(imageAlignment==="left") &&
            <div className="col-lg-6 col-xl-7 image-container">
              <ScrollAnimation
                animateIn="fadeInLeft"
                delay={ANIMATION_DELAY.default}
                initiallyVisible={false}
                animateOnce={true}
              >
                <img src={image} alt={header} width="566px" />
              </ScrollAnimation>
            </div>
          }

          {/* content */}
          <div className="col-lg-6 col-xl-5 text-container">
            <div className='content-container'>
              <ScrollAnimation
                animateIn={imageAlignment==="left" ? "fadeInRight" : "fadeInLeft"}
                delay={ANIMATION_DELAY.default}
                initiallyVisible={false}
                animateOnce={true}
              >
                <h1 className='text-secondary text-start'>{header}</h1>
                <hr />
                <ul>

                  {list.map((listItem, index) => {
                    return (
                      <li key={index}>{listItem}</li>
                    );
                  })}

                </ul>

                <div className="btn-row">
                  <a href="https://jobs.smartrecruiters.com/?company=DPDGroupUK1&keyword=Warehouse" target="_blank" rel="noreferrer">
                    <button className="btn btn-primary" onClick={() => handleClick(imageAlignment)}>View all Warehouse jobs</button>
                  </a>
                </div>
              </ScrollAnimation>
            </div>
          </div>

          {/* right image */}
          {(imageAlignment==="right") &&
            <div className="col-lg-6 col-xl-7 text-center text-lg-end image-container">
              <ScrollAnimation
                animateIn="fadeInRight"
                delay={ANIMATION_DELAY.default}
                initiallyVisible={false}
                animateOnce={true}
              >
                <img src={image} alt={header} width="566px" />
              </ScrollAnimation>
            </div>
          }

        </div>
      </div>
    </section>
  );
}

export default HexagonContent;
