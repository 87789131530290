import React from 'react';
import ScrollAnimation from 'react-animate-on-scroll';
import scrollArrow from 'assets/images/scroll-arrow.svg';

export const Banner = (props) => {
  const {title, subtitle, banner, alignment} = props;

  const scrollPage = () => {
    let bannerElement = document.getElementById('banner');
    let bannerHeight = bannerElement.offsetHeight;
    let headerHeight = 82;
    window.scrollTo(0, bannerHeight+headerHeight);
  }

  return (
    <div className={`banner ${alignment} ${banner}`} id="banner">
      <div className="container outer-container">
          <div className="content">
            <ScrollAnimation
              animateIn="fadeInLeft"
              initiallyVisible={true}
              animatePreScroll={true}
              animateOnce={true}
            >
              <h5>{subtitle}</h5>
              <h1>{title}</h1>
            </ScrollAnimation>
          </div>

        <img src={scrollArrow} alt="scroll down" width="58" className='btn-scroll' onClick={() => scrollPage()} />

      </div>
    </div>
  );
}

export default Banner;
