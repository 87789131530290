import React from 'react';
import ScrollAnimation from 'react-animate-on-scroll';
import {ANIMATION_DELAY} from 'constants/data';

export const TopContent = (props) => {

  return (
    <section className="top-content">
      <div className="container">
        <ScrollAnimation
          animateIn="fadeInLeft"
          delay={ANIMATION_DELAY.default}
          initiallyVisible={false}
          animateOnce={true}
        >
          <div className="content-container">
            <h2>Geopost is a European Leader in Parcel delivery and Solutions for the e-commerce market.</h2>
            <p>By choosing to work for DPD you'll be joining an open and inclusive culture, where people have the opportunity to flourish and shape their own careers.</p>
          </div>
        </ScrollAnimation>
      </div>
    </section>
  )
}

export default TopContent;
