

export const MOBILE_BREAKPOINT = 992;

export const ANIMATION_DELAY = {
    default: window.innerWidth < MOBILE_BREAKPOINT ? 50 : 300,
    fast: 100,
    slow: 400,
    slowest: window.innerWidth < MOBILE_BREAKPOINT ? 50 : 800,
}
