import React, {useEffect, useMemo} from 'react';
import { Trackable } from "@dpdgroupuk/react-event-tracker";
import Header from 'components/Header';
import Banner from 'components/Banner';
import Cards from 'components/Cards';
import HexagonContent from 'components/HexagonContent';
import Testimonial from 'components/Testimonial';
import TopContent from './components/TopContent';
import BottomContent from './components/BottomContent';
import driver1 from 'assets/images/driver1.png';
import driver2 from 'assets/images/driver2.png';

export const Index = ({ pageId }) => {

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const localContext = useMemo(
    () => ({
      page: "home",
      pageId,
      // ... more params in local context
    }),
    [pageId]
  );

  return (
    <Trackable
      loadId="TZA60SGFYFxgy5P1"
      interfaceId="bNka0SGFYFxgy5NH"
      context={localContext}
    >
      <div className='index'>

        <div className='hero-section'>

          <div className='gradient-overlay'></div>

          <Header />

          <Banner
            subtitle="All you need to know about BEING A"
            title="Warehouse Operative"
            banner="banner1"
            alignment="center"
          />

        </div>

        <TopContent />

        <Cards />

        <HexagonContent
          imageAlignment="right"
          image={driver1}
          header="What we’re looking for:"
          list={[
            "Love working in a team",
            "Ability to work in a fast paced environment",
            "Flexibility and adaptability to work across various areas of the warehouse",
            "Ability to lift, load and carry up to 23kg",
          ]}
        />

        <HexagonContent
          imageAlignment="left"
          image={driver2}
          header="Our Benefits:"
          list={[
            "Holiday trading",
            "Enhanced maternity package",
            "Free life assurance of 4 x salary on joining the pension scheme",
            "Free onsite parking for all employees",
            "Discounted shopping from 100s of retailers including up to 5% off supermarket shopping",
            "Milestone Days off to celebrate with your family and friends",
            "For those that regularly use a screen as part of their job, we pay for a free eye test and also give a voucher towards the cost of glasses."
          ]}
          anchorId="benefits"
        />

        <Testimonial />

        <BottomContent />

      </div>
    </Trackable>
  );
}

export default Index;
